body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navbar-collapse{
  flex-grow: inherit !important;
}

.stycky{    
  position: sticky !important;
  top: 0;
  z-index: 100;
}
.carousel-control-next-icon::after{ content: none !important;}
.carousel-control-prev-icon::after{content: none !important;}
.email{display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;}

  .emailhead{display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;}
    .per{width: 100% !important;}